<template>
  <div>
    <b-link
      v-if="room"
      :to="room.startURL?'':{
        name: 'room',
        params: {
          communityId: $route.params.communityId,
          name: room.key,
        },
      }"
      :href="room.startURL?room.startURL:''"
      :target="room.startURL?'_blank':''"
      class="text-dark"
    >
      <b-card
        class="small-card mb-1"
        @mouseenter="show = true"
        @mouseleave="show = false"
      >
        <safe-img
          class="thumbnail-img"
          :src="thumbnailSrc"
          :placeholder="placeholder"
          :class="{'thumbnail-img--fit' : room.backgroundURL}"
        />
        <event-period
          :start-date="room.openingTime"
          :end-date="room.closingTime"
          :all-day="room.allDay"
          class="text-primary font-weight-bolder mb-0 mt-1"
        />
        <h5 :class="openingTime ? 'title' : 'mt-1'">
          {{ titleShort }}
        </h5>
        <div v-if="show && $route.name != 'Resumen'" class="d-flex justify-content-end" :class="{'mt-2' : title.length < 30}">
          <div v-if="canEdit" @click.prevent="handleEditForm">
            <feather-icon
              icon="Edit2Icon"
              size="16"
              class="text-primary mr-75"
              role="button"
            />
          </div>
          <div v-if="canDelete" @click.prevent="deleteItem(room.key)">
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="text-primary"
              role="button"
            />
          </div>
        </div>
      </b-card>
    </b-link>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="room"
      @remove-item="handleRemoveItem"
    />
  </div>
</template>

<script>
import { BLink, BCard } from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import RoomsPlaceholder from '@/assets/images/placeholders/light/rooms.svg';
import EventPeriod from '@/views/apps/events/components/EventPeriod.vue';
import { checkPermissions } from "@/@core/utils/roles-utils";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'RoomSection',
  components: {
    BLink,
    BCard,
    SafeImg,
    EventPeriod,
    UnshareModal,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      namePage: '',
      show: false,
      isUnshareModalVisible: false,
    };
  },
  computed: {
    canEdit() {
      return (
        this.collective.isStaff ||
        checkPermissions(
          "update",
          "room",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    canDelete() {
      return (
        this.collective.isStaff ||
        checkPermissions(
          "delete",
          "room",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    title() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.room.name);
    },
    titleShort() {
      if (this.title.length < 40) {
        return this.title;
      }
      let trimmedString = this.title.substr(0, 40);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
      return `${trimmedString}...`;
    },
    placeholder() {
      return RoomsPlaceholder;
    },
    thumbnailSrc() {
      return getImageResource(this.room.backgroundURL);
    },
    openingTime() {
      return this.room.openingTime ? this.$moment(this.room.openingTime) : null;
    },
    closingTime() {
      return this.room.closingTime ? this.$moment(this.room.closingTime) : null;
    },
  },
  methods: {
    deleteItem() {
      this.isUnshareModalVisible = true;
    },
    async handleRemoveItem(response) {
      const temo = {
        tableName: "rooms",
        key: this.room.key,
      };
      if (response === true) {
        try {
          await this.$store.dispatch("deleteRooms", {
            item: temo,
            type: "rooms/delete",
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.room"),
              }),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.room"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    handleEditForm() {
      this.$emit('edit-room', this.room);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail-img {
  width: 100%;
  max-height: 100px;
    &--fit {
    object-fit: cover;
    }
}
.title {
  margin-top: 0.2rem;}
.small-card {
  // FIXME: This has been copyied from .small card in _card.scss
  // The .small-card logic seems flawed and needs some studying.
  // We need to study the horizontal container's cards and generate a usefull parent.
  height: 15.375rem;
  width: 15.938rem;
}

</style>
