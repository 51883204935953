import Vue from 'vue';
import Service from '@/config/service-identifiers';
import store from '@/store';
import {
  getByKey, getPage, getTotal, storePage, getUntilPage, storeItem,
} from '@/store/store-utils/StorageUtils';

/**
 * Rooms Store Module
 */
export default {
  namespaced: true,
  state: {
    byName: {},
  },
  getters: {
    getByKey: (state) => ({ key }) => getByKey(key, state),
    getByName: (state) => (name) => state.byName[name],
    getPage: (state) => (
      ({ page, perPage }) => getPage({ page, perPage }, state)
    ),
    getUntilPage: (state) => (
      ({ page, perPage }) => getUntilPage({ page, perPage }, state)
    ),
    total: (state) => getTotal(state),
  },
  mutations: {
    storePage(state, { data, meta }) {
      storePage({ data, meta }, state);
    },
    storeItems(state, { items, skipByKey }) {
      items.forEach((item) => {
        if (!skipByKey) storeItem(item, state);
        Vue.set(state.byName, item.name, item);
      });
    },
  },
  actions: {
    async fetchRoomsPage(
      { commit, getters, rootGetters },
      { page, perPage, force },
    ) {
      const storedPage = getters.getPage({ page, perPage });
      if (storedPage && !force) {
        return storedPage;
      }

      const response = await store.$service[Service.BackendClient].get(
        'rooms',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
            page,
            count: perPage,
          },
        },
      );

      commit('storePage', response.data);
      commit('storeItems', { items: response.data.data, skipByKey: true });

      return getters.getPage({ page, perPage });
    },
    async fetchRoomByKey(
      { commit, getters, rootGetters },
      { key, force },
    ) {
      const room = getters.getByKey({ key });

      if (room && !force) {
        return;
      }

      const response = await store.$service[Service.BackendClient].get(
        'rooms',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
            roomKeys: [key],
          },
        },
      );

      if (!response.data.data[0]) {
        return;
      }
      commit('storeItems', { items: response.data });
    },
    async fetchRoomByName(
      { commit, getters, rootGetters },
      { roomName, force },
    ) {
      const room = getters.getByName(roomName);

      if (room && !force) {
        return room;
      }

      const response = await store.$service[Service.BackendClient].get(
        'rooms',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
            roomName,
          },
        },
      );

      if (!response.data.data[0]) {
        return null;
      }

      commit('storeItems', { items: response.data.data });

      return getters.getByName(roomName);
    },
  },
};
