import ListContainer from '@core/constants/ListContainer';
import BaseHorizontalContainer from '../base/BaseHorizontalContainer.vue';
import BaseGridContainer from '../base/BaseGridContainer.vue';

export default {
  name: 'ItemsListMixin',
  props: {
    container: {
      type: String,
      default: ListContainer.Horizontal,
      validator(container) {
        return Object.values(ListContainer).includes(container);
      },
      perPage: {
        type: Number,
        default: 8,
      },
    },
  },
  data() {
    return {
      items: [],
      currentPage: 0,
      loading: true,
      isLoadingNextPage: false,
    };
  },
  computed: {
    containerComponent() {
      switch (this.container) {
        case ListContainer.Horizontal:
          return BaseHorizontalContainer;
        case ListContainer.Vertical:
          throw new Error('Vertical Container not yet implemented');
        case ListContainer.Grid:
          return BaseGridContainer;
        default:
          throw new Error('Invalid container');
      }
    },
    total() {
      return 0;
    },
  },
  async created() {
    this.loading = true;
    await this.handleLoadNextPage();
    this.loading = false;
  },
  methods: {
    async handleLoadNextPage() {
      this.isLoadingNextPage = true;
      this.currentPage += 1;
      this.items.push(
        ...await this.loadPage({
          page: this.currentPage,
          perPage: this.perPage,
        }),
      );
      this.$emit('total-updated', this.total);
      this.isLoadingNextPage = false;
    },
    async loadPage() {
      throw new Error('LoadPage must be implemented');
    },
  },
};
