export const ROOMS_STORE_MODULE_NAME = 'rooms';

export const ROOMS_ACTIONS = {
  fetchRoomsPage: `${ROOMS_STORE_MODULE_NAME}/fetchRoomsPage`,
  fetchRoomByKey: `${ROOMS_STORE_MODULE_NAME}/fetchRoomByKey`,
  fetchRoomByName: `${ROOMS_STORE_MODULE_NAME}/fetchRoomByName`,
};

export const ROOMS_GETTERS = {
  getPage: `${ROOMS_STORE_MODULE_NAME}/getPage`,
  getUntilPage: `${ROOMS_STORE_MODULE_NAME}/getUntilPage`,
  total: `${ROOMS_STORE_MODULE_NAME}/total`,
  getByKey: `${ROOMS_STORE_MODULE_NAME}/getByKey`,
  getByName: `${ROOMS_STORE_MODULE_NAME}/getByName`,
};
